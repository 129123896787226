import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import Navbar from "../../components/Navbar/Navbar";
import bg from "../../images/Banner/banner-bg.jpg";
import Lord from "../../images/Banner/lord-ganesh.png";

import cImg1 from "../../images/slider/shape15.svg";
import cn1 from "../../images/slider/corner1.svg";
import cn2 from "../../images/slider/corner2.svg";
import cn3 from "../../images/slider/corner3.svg";
import cn4 from "../../images/slider/corner4.svg";

import shape1 from "../../images/slider/shape13.svg";
import shape2 from "../../images/slider/shape14.svg";
import { connect } from "react-redux";
import { capitalize } from "../../utils/string";
import moment from "moment";
import { Button } from "@mui/material";
import { confetti } from "tsparticles-confetti";
import { addDaysToDate } from "../../utils";
import Preloader from "../../components/Preloader/Preloader";
import { Slide } from "react-awesome-reveal";

const Banner = (props) => {
  const {
    brideName,
    groomName,
    address,
    eventDate,
    onOpenInvite,
    isPreview,
    bannerPhoto,
    loading,
  } = props;
  const bride = brideName.split(" ")[0];
  const groom = groomName.split(" ")[0];

  useEffect(() => {
    AOS.init();
  }, []);

  const confettiOptions = {
    angle: 90,
    count: 1000,
    position: {
      x: 50,
      y: 70,
    },
    spread: 200,
    startVelocity: 80,
    decay: 0.9,
    gravity: 0,
    drift: 0,
    ticks: 200,
    colors: "#b4b4a6",
    scalar: 1,
    zIndex: 100,
    disableForReducedMotion: true,
  };

  const handleClickOpen = (e) => {
    (async () => {
      await confetti("tsparticles", confettiOptions);
    })();
    setTimeout(() => {
      onOpenInvite && onOpenInvite(e, true);
    }, 1000);
  };

  const countDownDate = isPreview ? addDaysToDate(10) : eventDate;

  return (
    <Fragment>
      <section
        className="wpo-invitation-area-s2"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="hero-container">
          <div className="hero-inner">
            <Slide direction="up" duration="1000" triggerOnce="true">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="wpo-event-wrap">
                      <div className="wpo-event-item">
                        <div className="wpo-event-text">
                          {!loading ? (
                            <>
                              {/* <p className="fadeInSlow">
                                <img src={Lord} alt="" />
                              </p> */}
                              <p
                                style={{ marginBottom: 10 }}
                                className="fadeInSlow"
                              >
                                You're invited to the 1st birthday of
                              </p>
                              <h2 className="fadeInSlow">Jaisvi</h2>
                              <div className="inner-shape fadeInSlow">
                                <img src={cImg1} alt="" />
                              </div>
                              <p
                                className="fadeInSlow"
                                style={{
                                  fontSize: 22,
                                  marginBottom: 20,
                                }}
                              >
                                16th December, 2024
                                <br />
                              </p>
                              <ul className="fadeInSlow">
                                <li>
                                  The Grand Neelkanth, Aster hall, 2nd Floor,
                                  Kankubaug Mall, Nirant Cross Road, Vastral,
                                  Ahmedabad
                                </li>
                                <li>
                                  <Button
                                    className={`btn`}
                                    onClick={handleClickOpen}
                                  >
                                    Open Invitation
                                    {/* <div className="icon">
                                <i className="fi flaticon-right-arrow-1"></i>
                              </div> */}
                                  </Button>
                                </li>
                              </ul>
                            </>
                          ) : (
                            <Preloader />
                          )}
                        </div>
                        <div className="shape-1">
                          <img src={cn1} alt="" />
                        </div>
                        <div className="shape-2">
                          <img src={cn2} alt="" />
                        </div>
                        <div className="shape-3">
                          <img src={cn3} alt="" />
                        </div>
                        <div className="shape-4">
                          <img src={cn4} alt="" />
                        </div>
                      </div>
                      <div className="frame-shape-1">
                        <img src={shape1} alt="" />
                      </div>
                      <div className="frame-shape-2">
                        <img src={shape2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
    brideName: state.inviteDetails.invite?.bride?.name,
    groomName: state.inviteDetails.invite?.groom?.name,
    eventDate: state.inviteDetails.invite?.eventDate,
    address: state.inviteDetails.invite?.venue?.address,
    isPreview: state.inviteDetails?.isPreview,
    loading: false,
  };
};

export default connect(mapStateToProps)(Banner);
