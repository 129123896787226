import React from "react";
import hero2 from "../../images/slider/hero4-flower.png";
import bg from "../../images/slider/hero-bg22.png";
import heroImage from "../../images/default/hero-2.png";
import { Fade, Slide } from "react-awesome-reveal";

import TimeCountDown from "../countdown";
import { connect } from "react-redux";
import {
  addDaysToDate,
  getCloudinaryFullPath,
  getDayDiffFromToday,
} from "../../utils";
import moment from "moment";
import TypeWriter from "../TypeWriter/TypeWriter";
import Elephant from "../../images/hero/heroIndian/elephant.png";
import Mandola from "../../images/hero/heroIndian/mandola.png";
import shape from "../../images/couple/couple-shape.png";
import IndianShape1 from "../../images/indian-shape1.svg";
import IndianShape2 from "../../images/indian-shape2.svg";

const HeroIndian = ({
  brideName = "",
  groomName = "",
  eventDate = "",
  bannerPhoto,
  isPreview = false,
}) => {
  const brideFname = brideName.split(" ")[0];
  const groomFname = groomName.split(" ")[0];

  const countDownDate = isPreview ? addDaysToDate(10) : eventDate;

  return (
    <section className="corner-images-section">
      <img
        src={`${Mandola}`}
        alt="Top Left"
        className="corner-image top-left"
      />
      <img
        src={`${Mandola}`}
        alt="Top Right"
        className="corner-image top-right"
      />
      <img
        src={`${Elephant}`}
        alt="Bottom Left"
        className="corner-image bottom-left"
      />
      <img
        src={`${Elephant}`}
        alt="Bottom Right"
        className="corner-image bottom-right"
      />
      <div className="section-content wpo-static-hero-inner static-hero-s3 hero-container">
        <div className="slide-title circleImg">
          <img src={`${IndianShape1}`} alt="" />

          <h2>
            {brideFname} <span>&</span> {groomFname}
          </h2>
          <img src={`${IndianShape2}`} alt="" />

          {/* <div className="shape circleShape">
            <img src={shape} alt="" />
          </div> */}
        </div>
        <div className="title-container">
          <Fade
            className="animate__animated animate__fadeInLeft"
            delay={400}
            duration={2000}
            triggerOnce="true"
          >
            <h3 className="title">Save the Date</h3>
          </Fade>
          <TypeWriter
            weddingDate={moment(countDownDate).format("MMMM DD, YYYY")}
          />
        </div>
        {countDownDate && (
          <div className="wpo-wedding-date wedding-date row">
            <div className="col col-xs-12">
              <div className="clock-grids">
                <div id="clock">
                  <div id="clock">
                    <TimeCountDown weddingDate={countDownDate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    brideName: state.inviteDetails.invite?.bride?.name,
    groomName: state.inviteDetails.invite?.groom?.name,
    eventDate: state.inviteDetails.invite?.eventDate,
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
    isPreview: state.inviteDetails?.isPreview,
  };
};
export default connect(mapStateToProps)(HeroIndian);
