import { useEffect } from "react";
import { confetti } from "tsparticles-confetti";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

import MyParticlesComponent from "../ParticlesComponent/ParticlesComponent";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import SectionScroll from "./SectionScroll";

export default function BirthdayHome() {
  // const confettiOptions = {
  //   angle: 90,
  //   count: 1000,
  //   position: {
  //     x: 50,
  //     y: 70,
  //   },
  //   spread: 200,
  //   startVelocity: 80,
  //   decay: 0.9,
  //   gravity: 0,
  //   drift: 0,
  //   ticks: 200,
  //   colors: "#b4b4a6",
  //   scalar: 1,
  //   zIndex: 100,
  //   disableForReducedMotion: true,
  // };

  // const callConfetti = async () => {
  //   await confetti("tsparticles", confettiOptions);
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     callConfetti();
  //   }, 1000);
  // }, []);

  return (
    <div className={`fadeInSlow scroll-container`}>
      <MyParticlesComponent />
      <div className="section">
        <Section1 />
      </div>
      <Section2 />
      <Section3 />
      <Section5 />
      <Section4 />
      <Footer footerClass={"wpo-site-footer-s2"} />
      <SectionScroll />
    </div>
  );
}
