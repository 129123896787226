import { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import HomePage from "../HomePage";
import HomePage2 from "../HomePage2";
import HomePage4 from "../HomePage4/HomePage4";

import {
  setInvitationDetails,
  setInvitationLoading,
  setInvitationOpen,
  setInvitationPreview,
  setInvitationTheme,
} from "../../store/actions/action";
import { connect } from "react-redux";
import { callInvitationAPI, checkValidityAPI } from "../../utils/api";
import Banner from "../Banner/Banner";
import MarketingBanner from "./Modal";
import { getQueryParamFromUrl, isPreviewTemplateWithPath } from "../../utils";
import AmantrikaHome from "../../components/AmantrikaHome/AmantrikaHome";
import { Themes } from "../../utils/constant";

import { ClassicEleganceData } from "../../json/classicElegance";
import { ModernChicData } from "../../json/modernChic";
import BirthdayHome from "../BirthdayHome/BirthdayHome";
import BannerBirthday from "../BirthdayHome/BannerBirthday";
// import HomePage1 from "../HomePage1";

const InvitationWrapper = (props) => {
  const { isInviteOpen, theme, loading, bannerPhoto, groomName, brideName } =
    props;

  const location = useLocation();
  const navigate = useNavigate();

  const themeName = getQueryParamFromUrl(location?.search);

  let { pathname } = useParams();
  const [noInvite, setNoInvite] = useState(false);
  const [showMarketingBanner, setShowMarketingBanner] = useState(false);
  const [hideLayer, setHideLayer] = useState(false);
  const [isBirthday, setIsBirthday] = useState(pathname === "test");

  const isPreviewWithPath = isPreviewTemplateWithPath(
    pathname,
    location?.pathname
  );
  const isPreview = location?.pathname?.includes("/preview");

  useEffect(() => {
    if (pathname === "jaisvi-darekar") {
      setIsBirthday(true);
    } else if (isPreviewWithPath) {
      sessionStorage.setItem("isPreviewWithPath", true);
      handlePreviewWithPath();
    } else if (!isPreview && pathname) {
      sessionStorage.setItem("isPreviewWithPath", false);
      checkValidity();
    } else if (isPreview && !pathname) {
      sessionStorage.setItem("isPreviewWithPath", false);
      handlePreview();

      setTimeout(() => {
        props.setInvitationLoading(false);
      }, 2000);
    } else {
      sessionStorage.setItem("isPreviewWithPath", false);
      props.setInvitationLoading(false);
      handleNoInvite();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setHideLayer(!loading);
      }, 1900);
    }
  }, [loading]);

  useEffect(() => {
    if (isInviteOpen && pathname) {
      setTimeout(() => {
        // setShowMarketingBanner(true);
      }, 30000);
    }
  }, [isInviteOpen]);

  const handlePreview = () => {
    props.setInvitationPreview(true);
    if (themeName?.key === "theme") {
      props.setInvitationTheme(themeName?.value);
      switch (themeName?.value) {
        case Themes.classicElegance:
          props.setInvitationDetails(ClassicEleganceData);
          return;
        case Themes.modernChic:
          props.setInvitationDetails(ModernChicData);
          return;
        default:
          return;
      }
    }
  };

  const handlePreviewWithPath = () => {
    getInvitationData();
  };

  const checkValidity = async () => {
    try {
      const isValid = await checkValidityAPI(pathname);
      if (isValid) {
        getInvitationData();
      } else {
        toast.info(
          "The invitation you are trying to access has reached its validity period."
        );
        handleNoInvite();
      }
    } catch (error) {
      handleNoInvite();
    }
  };

  const getInvitationData = async () => {
    let { invite, status } = await callInvitationAPI(
      pathname,
      isPreviewWithPath
    );
    if (status === 200) {
      props.setInvitationDetails(invite);
      props.setInvitationLoading(false);
    } else {
      handleNoInvite();
      props.setInvitationLoading(false);
    }
  };

  const handleNoInvite = () => {
    props.setInvitationLoading(false);
    navigate("/");
    setNoInvite(true);
  };

  const handleOpenInvite = (event) => {
    setTimeout(() => {
      props.setInvitationOpen(true);
    }, 500);
    // Animate the transition effect using Anime.js or CSS transitions
  };

  const renderThemeBasedPage = () => {
    switch (theme) {
      case Themes.classicElegance:
        return <HomePage4 />;
      case Themes.modernChic:
        return <HomePage />;
      case Themes.indianTouch:
        return <HomePage2 />;
      // case Themes.eternalGrace:
      //   return <HomePage1 />;
      default:
        return <HomePage2 />;
    }
  };

  const renderInvite = () => {
    return (
      <>
        <Helmet>
          <title></title>
          <meta
            data-auid="og:title"
            property="og:title"
            name="title"
            content={`${groomName} and ${brideName}'s Wedding Invitation`}
          />
          <meta data-auid="og:type" property="og:type" content={"website"} />

          <meta
            data-auid="og:image"
            property="og:image"
            content={bannerPhoto}
          />
        </Helmet>
        <div style={{ display: isInviteOpen ? "none" : "block" }}>
          <div className="inviteBannerWrapper">
            {isBirthday ? (
              <BannerBirthday onOpenInvite={handleOpenInvite} />
            ) : (
              <Banner onOpenInvite={handleOpenInvite} />
            )}
          </div>
          {/* <div className={`${!hideLayer ? "preloaderWrapper" : ""}`}>
            <div
              className={`${!hideLayer ? "preloaderClippath" : ""} ${
                !loading ? "preloaderHide" : ""
              }`}
            />
          </div> */}
        </div>
        <div style={{ display: isInviteOpen ? "block" : "none" }}>
          {isBirthday ? <BirthdayHome /> : renderThemeBasedPage()}
          {showMarketingBanner && (
            <MarketingBanner onClose={() => setShowMarketingBanner(false)} />
          )}
        </div>
      </>
    );
  };

  return noInvite ? <AmantrikaHome /> : renderInvite();
};

const mapStateToProps = (state) => {
  return {
    isInviteOpen: state.inviteDetails?.isOpen || false,
    theme:
      state.inviteDetails?.invite?.themeInfo?.identifier ||
      state.inviteDetails?.theme ||
      "",
    loading: state.inviteDetails?.loading,
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
    brideName: state.inviteDetails.invite?.bride?.name,
    groomName: state.inviteDetails.invite?.groom?.name,
  };
};

export default connect(mapStateToProps, {
  setInvitationDetails,
  setInvitationOpen,
  setInvitationPreview,
  setInvitationTheme,
  setInvitationLoading,
})(InvitationWrapper);
//
