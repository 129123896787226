import React from "react";
import { Link } from "react-router-dom";
import IndianCoupleVector from "../../images/indian-couple.png";
import shape from "../../images/couple/couple-shape2.png";
import { connect } from "react-redux";
import Hashtag from "../Hashtag/Hashtag";
import { capitalize } from "@mui/material";

const CoupleIndian = ({ bride = {}, groom = {} }) => {
  return (
    <section className="wpo-couple-section-s6 section-padding" id="couple">
      <div className="container">
        <div className={`couple-section-message`}>
          {/* <span>{subTitle}</span> */}
          <h2>
            We request the honor of your presence as we unite our lives in
            marriage
          </h2>
        </div>
        <Hashtag />
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <h3>{capitalize(bride?.name)}</h3> <p>{bride.about || ""}</p>
                <p>
                  <span>D/o</span> {capitalize(bride?.fatherName || "")} and{" "}
                  {capitalize(bride?.motherName || "")} <br /> From{" "}
                  {bride?.nativePlace} <br />
                  {bride?.phone && <i className="fi flaticon-phone-call" />}
                  {bride?.phone}
                </p>
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-12">
              <div className="middle-couple-pic-wrap zIndex">
                <div className="middle-couple-pic">
                  <div className="middle-couple-pic-inner">
                    <img src={IndianCoupleVector} alt="" />
                  </div>
                  <div className="couple-shape">
                    <img src={shape} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <h3>{capitalize(groom?.name)}</h3> <p>{groom.about || ""}</p>
                <p>
                  <span>D/o</span> {capitalize(groom?.fatherName || "")} and{" "}
                  {capitalize(groom?.motherName || "")} <br /> From{" "}
                  {groom?.nativePlace} <br />
                  {groom?.phone && <i className="fi flaticon-phone-call" />}
                  {groom?.phone}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
    // inviteSide: state.inviteDetails.invite?.inviteSide,
  };
};

export default connect(mapStateToProps)(CoupleIndian);
