import React from "react";
import { Helmet } from "react-helmet";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import { connect } from "react-redux";

const App = ({ bannerPhoto }) => {
  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
  };
};
export default connect(mapStateToProps)(App);
