import { Fade } from "react-awesome-reveal";
import SectionTitle from "../../components/SectionTitle";
import bannerImage from "../../images/birthday/Jbanner.jpeg";

const Section3 = (props) => {
  return (
    <section className="wpo-event-section-s4 section-padding" id="event">
      <div className="container" style={{ minHeight: 0 }}>
        <SectionTitle subTitle={"You are Invited"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-12">
              <Fade delay={100} duration={500} triggerOnce="true">
                <div className="wpo-event-item">
                  <div className="wpo-event-text">
                    <div className="title">
                      <h2>Party Celebration</h2>
                    </div>
                    <ul>
                      <li>
                        Monday, 16 Dec. 2024 <br /> 6:30 PM Onwards
                      </li>
                      <li>
                        The Grand Neelkanth, Aster hall, 2nd Floor, Kankubaug
                        Mall, Nirant Cross Road, Vastral, Ahmedabad
                      </li>
                      <li>+91 8980430369, +91 9913825137</li>
                    </ul>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="quoteContainer section-padding">
        <div className="content section">
          <div className="photos">
            <Fade delay={400} duration={1000} triggerOnce="true">
              <div className="thumbnails">
                <img src={bannerImage} alt="quote-bg" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
