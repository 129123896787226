import React, { useRef } from "react";
import CoupleSection2 from "../../components/CoupleSection2/CoupleSection2";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import VenueSection from "../../components/VenueSection/VenueSection";
import InviteeSection from "../../components/InviteeSection/InviteeSection";
import MyParticlesComponent from "../ParticlesComponent/ParticlesComponent";
import EventSection4 from "../../components/EventSection4/EventSection4";
import QuoteSection from "../../components/QuoteSection/QuoteSection";
import WeddingDate from "../../components/WeddingDate/WeddingDate";
import HeroIndian from "../../components/heroIndian/heroIndian";
import CoupleIndian from "../../components/CoupleIndian/CoupleIndian";

const HomePage = () => {
  const containerRef = useRef(null);
  return (
    <div
      ref={containerRef}
      className={`fadeInSlow scroll-container hero-indian`}
    >
      <MyParticlesComponent />
      <div className="section">
        <HeroIndian />
        {/* <WeddingDate /> */}
      </div>
      <CoupleIndian />
      <QuoteSection />
      <EventSection4 haveBackground={true} />
      <VenueSection />
      <InviteeSection haveBackground={true} />
      {/* <StorySection2 /> */}
      {/* <PortfolioSection /> */}
      {/* <RsvpSection /> */}
      {/* <RsvpSectionS3 bg={bg} /> */}
      {/* <BlogSection /> */}
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </div>
  );
};

export default HomePage;
