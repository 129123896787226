import { Fade } from "react-awesome-reveal";
import image2 from "../../images/birthday/Jbanner2.jpeg";

export default function Section2() {
  return (
    <>
      <div className="container">
        <main className="main-content">
          <Fade
            className="image-container"
            delay={400}
            duration={1000}
            triggerOnce="true"
          >
            <div className="blob-background"></div>
          </Fade>
          <h1 className="title">Birthday Queen</h1>

          <p className="description">
            Woohoo🎉! I'm turning one and I can't wait to celebrate with all my
            favorite people🥰 as I take my first steps into a new year of fun
            and adventure.
          </p>
          <p className="testimonial-card__author-title">- Jaisvi Darekar</p>
        </main>
      </div>
      <div className="quoteContainer section-padding">
        <div className="content section">
          <div className="photos">
            <Fade delay={400} duration={1000} triggerOnce="true">
              <div className="thumbnails">
                <img src={image2} alt="quote-bg" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
